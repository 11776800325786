<template>
  <!-- 新增系统管理员页面 -->
  <div>
    <!-- 填写表单 -->
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      label-width="120px"
      :rules="rules"
    >
      <el-form-item label="用户名" prop="adminName">
        <el-input
          v-model="ruleForm.adminName"
          placeholder="请输入用户名"
          size="mini"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="请输入密码">
        <el-input
          placeholder="请输入密码"
          v-model="ruleForm.pwd"
          show-password
          size="mini"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="真实姓名" prop="adminTureName">
        <el-input
          v-model="ruleForm.adminTureName"
          placeholder="请输入真实姓名"
          size="mini"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="adminTel">
        <el-input
          v-model="ruleForm.adminTel"
          placeholder="请输入手机号"
          size="mini"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="createBy">
        <el-input
          v-model="ruleForm.createBy"
          placeholder="备注"
          size="mini"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="角色" prop="roles">
        <el-select
          v-if="ruleForm.roleList"
          v-model="ruleForm.roleList[0].id"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.roleName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户状态" prop="status">
        <el-radio v-model="ruleForm.status" :label="0">可用</el-radio>
        <el-radio v-model="ruleForm.status" :label="1">不可用</el-radio>
      </el-form-item>
      <el-form-item label="">
        <div>
          <el-button type="" @click="getBack">返回</el-button>
          <el-button type="warning" @click="submitForm('ruleForm')"
            >校验</el-button
          >
          <el-button type="primary" @click="goAdd">保存</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "",
      ruleForm: {
        checked1: false,
        checked2: true,
        adminName: "", // 用户名
        pwd: "", //密码
        adminTureName: "", // 真实姓名
        adminTel: "", //手机号
        createBy: "", //备注
        status: "", //用户状态
      },
      //   角色选择
      options: [],
      // 用户状态
      tions: [
        {
          value: "1",
          label: "不可用",
        },
        {
          value: "0",
          label: "可用",
        },
      ],
      //   验证规则
      rules: {
        adminName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        adminTureName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        adminTel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        roles: [{ required: true, message: "请选择角色", trigger: "blur" }],
        status: [{ required: true, message: "请选择状态", trigger: "blur" }],
      },
    };
  },
  created() {
    this.adminList();
    // this.dmist();
    // 获取系统角色
    this.axios
      .get("/admin/role/selelctRoleList", {
        pageNumber: 1,
        pageSize: 999,
      })
      .then((res) => {
        this.options = res.data.data.records;
        // 数组中包对象的del值为1 就删除
        this.options = this.options.filter((item) => item.del !== 1);
        console.log(this.options);
      });
  },
  methods: {
    dmist() {
      let newid = this.$route.query.Id;
      // 获取角色
      this.axios
        .get("/admin/roleAction/selectRoleActionList", {
          roleId: newid,
        })
        .then((res) => {
          this.text = res.data.data.roleName;
        });
    },
    // 获取管理员账号信息
    adminList() {
      let newid = this.$route.query.Id;
      this.axios
        .get("/admin/adminRole/getAdminRoleList", {
          userId: newid,
        })
        .then((res) => {
          console.log(res);
          this.ruleForm = res.data.data;
          let arr = this.ruleForm.roleList;
          console.log(this.ruleForm);
          this.ruleForm.pwd = null;
          // this.ruleForm.roleList = [];
          // if (arr) {
          //   arr.forEach((item, index) => {
          //     this.ruleForm.roleList.push(item.id);
          //   });
          // }
        });
    },
    //   更新
    goAdd() {
      console.log(this.ruleForm.roleList);
      this.ruleForm.roles = [];
      this.ruleForm.roles.push(this.ruleForm.roleList[0].id);
      this.axios.put("/admin/admin/updateAdmin", this.ruleForm).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: res.mesg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.mesg,
            type: "error",
          });
        }
      });

      // this.axios
      //   .post("/admin/adminRole/updateAdminRole", {
      //     adminName: this.ruleForm.adminName,
      //     userId: newid,
      //     pwd: this.ruleForm.pwd,
      //     createBy: this.ruleForm.createBy,
      //   })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => {});
    },
    //   保存成功
    open2() {
      this.$message({
        message: "保存成功",
        type: "success",
      });
    },
    //   返回上一页面
    getBack() {
      this.$router.go(-1);
    },
    //保存
    Save(formName) {
      this.submitForm(formName);
      this.open2();
      this.getBack();
    },
    // 校验
    submitForm(formName) {
      console.log(this.ruleForm);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
